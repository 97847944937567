import EmployeeForm from "./EmployeeForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import React, { useState, useEffect } from "react";
import http from "../../Common/RestAPIHandler";
import commonConstants from "../../Common/CommonConstants";
import { connect } from "react-redux";
import { format } from "date-fns-tz";
import { ConvertDateToLocale } from "../../Common/Utility";
import EmployeeCredentials from "./EmployeeCredentials";
import Checkbox from "@material-ui/core/Checkbox";
import SnackbarComponent from "../../Common/Snackbar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Grid, Tooltip, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatPhoneNumber } from "../../Common/Utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Pwned from "../../../Images/pwned.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Dialog } from "@material-ui/core";
import EmployeeDetails from "./EmployeeDetails";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";

const useStyles = makeStyles((theme) => ({
  checkboxColor: {
    color: theme.colorScheme + "!important",
  },
  button: {
    height: 30,
    width: 30,
    background: theme.colorScheme,
    color: "white",
    margin: 1,
  }
}));

const Employees = (props) => {
  //const selectedSite = localStorage.getItem("Site");
  const classes = useStyles();
  const [activeIds, setActiveIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [employeeType, setEmployeeType] = useState();
  const [eType, setEType] = useState("All");
  const [sites, setSites] = useState();
  const [site, setSite] = useState("All");
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [unchangedData, setUnchangedData] = useState([]);
  const [showActive, setShowActive] = useState(true);
  const [showDisabled, setShowDisabled] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [objectId, setObjectId] = useState();

  let url = `company_employees?company_id=${props.user.company_id}`;
  if (!props.user.role.employees_hidden) {
    url += `&is_hidden=False`;
  }

  const exportFormat = commonConstants.employeeExportFormat;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    var showId = new URLSearchParams(window.location.search).get("show_id");
    if (showId) {
      setObjectId(showId);
      props.history.replace({
        search: window.location.search.replace("&show_id=" + showId, ""),
      });
    }

    http.Get(
      `categories?category_type=Account Type`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
        }
        setCategories(valueMap);
      },
      () => {}
    );

    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Employee Type`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
        }
        setEmployeeType(valueMap);
      },
      () => {}
    );

    http.Get(
      `companies/${props.user.company_id}/sites`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].site_name;
        }
        setSites(valueMap);
      },
      () => {}
    );
  }, []);

  const toggleActiveIds = (props, object, property) => {
    if (activeIds.includes(object.id)) {
      setActiveIds(
        activeIds.filter((activeId) => {
          return activeId !== object.id;
        })
      );
      object["indicator"] = <ExpandMoreIcon />;
    } else {
      var ids = [].concat(activeIds);
      ids.push(object.id);
      setActiveIds(ids);
      object["indicator"] = <ExpandLessIcon />;
    }
  };

  const tableColumns = [
    {
      id: "indicator",
      label: "",
      minWidth: 10,
      sortable: false,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile ? null : toggleActiveIds,
    },
    {
      id: "employee_name",
      label: "NAME",
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile
        ? null
        : (props, object, property) => {
            setObjectId(object.id);
          },
    },
    {
      id: "category_name",
      label: "",
      minWidth: 80,
      sortable: false,
      colored: false,
    },
    {
      id: "site_name",
      label: "SITE",
      minWidth: 175,
      sortable: true,
      colored: false,
    },
    {
      id: "email",
      label: "EMAIL",
      minWidth: 175,
      sortable: true,
      colored: false,
    },
    {
      id: "phone_number",
      label: "PHONE",
      minWidth: 175,
      sortable: true,
      colored: false,
    },
    {
      id: "work_station",
      label: "WORKSTATION",
      minWidth: 150,
      sortable: true,
      colored: false,
    },
    {
      id: "eagle_eye",
      label: "",
      minWidth: 10,
      style: { marginLeft: "17px" },
      sortable: true,
      disableTooltip: true,
    },
    {
      id: "role_name",
      label: "PORTAL ROLE",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "is_hidden",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
  ];

  useEffect(() => {
    fetchData();
    // if (sites && categories) {
    //   fetchData();
    // }
  }, [sites, categories]);

  useEffect(() => {
    var dataList = [];
    data.map((employee) => {
      if ((site === "All" || employee.location_id === site )&& (employee.category === eType ||eType==="All" )) {
        if (showActive && employee.status === "Active") {
          dataList.push(employee);
        }
        if (showDisabled && employee.status === "Inactive") {
          dataList.push(employee);
        }
      }
    });
    setShowData(dataList);
  }, [data, showActive, showDisabled, site,eType]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setUnchangedData(
          data.map((item) => {
            const obj = {};
            Object.keys(exportFormat).forEach((key) => {
              if (commonConstants.dateTimeFields.includes(key))
                obj[[exportFormat[key]]] = format(
                  ConvertDateToLocale(item[key]),
                  "MM/dd/yyyy"
                );
              else obj[[exportFormat[key]]] = item[key];
            });
            return obj;
          })
        );
        data.map((employee) => {
          employee["rp_disabled"] = !employee["status"];
          employee["status"] = employee["status"] ? "Active" : "Inactive";
          employee["phone_number"] = formatPhoneNumber(
            employee["phone_number"]
          );
          employee["is_hidden"] = employee["is_hidden"] ? (
            <Tooltip arrow={true} title="Visibility">
              <VisibilityIcon color="disabled" />
            </Tooltip>
          ) : (
            <Tooltip arrow={true} title="Visibility">
              <VisibilityIcon />
            </Tooltip>
          );

          employee["eagle_eye"]
            ? (employee["eagle_eye"] = (
                <Tooltip arrow={true} title="Unsafe Credentials!">
                  <img
                    style={{
                      maxWidth: 25,
                      maxHeight: 25,
                      margin: 4,
                    }}
                    src={Pwned}
                  ></img>
                </Tooltip>
              ))
            : (employee["eagle_eye"] = "");

          if (!employee["role_name"]) {
            employee["role_name"] = "NONE";
          }

          if (!employee["category_order"]) {
            employee["category_order"] = 999;
          }

          employee["subData"] = (
            <EmployeeCredentials
              {...props}
              categories={categories}
              employeeId={employee["id"]}
            />
          );

          employee["indicator"] = <ExpandMoreIcon />;
          employee["mobile_view_button"] = (
            <>
            {employee.mobile &&
              <IconButton
                disableTouchRipple
                className={classes.button}
                edge="start"
                href={`tel://${employee.mobile}`}
              >
                <PhoneIcon fontSize="small" />
              </IconButton>
            }
            {employee.email &&
              <IconButton
                className={classes.button}
                href={`mailto:${employee.email}`}
              >
                <MailOutlineIcon fontSize="small" />
              </IconButton>
            }
            </>
          );
        });

        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {objectId && (
        <Dialog
          fullWidth
          maxWidth="xl"
          open={Boolean(objectId)}
          onClose={() => {
            setObjectId(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <EmployeeDetails
              {...props}
              objectId={objectId}
              handleClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              onClose={() => {
                setObjectId(null);
              }}
            />
          </div>
        </Dialog>
      )}
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={
            <Grid container>
              <Grid item>Employees </Grid>
              <Grid item style={{ fontSize: "15px", marginLeft: "20px" }}>
                <Checkbox
                  className={classes.checkboxColor}
                  checked={showActive}
                  onClick={(e) => {
                    setShowActive(!showActive);
                  }}
                />
                Active
              </Grid>
              <Grid item style={{ fontSize: "15px" }}>
                <Checkbox
                  className={classes.checkboxColor}
                  checked={showDisabled}
                  onClick={(e) => {
                    setShowDisabled(!showDisabled);
                  }}
                />
                Inactive
              </Grid>
              <Grid item style={{ fontSize: "15px", marginLeft: "9px",   display: "flex", justifyContent: "center", alignItems: 'center'  }}>
                <Select
                  value={site}
                  onChange={(e) => {
                    setSite(e.target.value);
                  }}
                  style={{ fontSize: "15px"}}
                >
                  <MenuItem value="All">All Sites</MenuItem>
                  {sites &&
                    Object.entries(sites).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item style={{ fontSize: "15px", marginLeft: "9px",  display: "flex", justifyContent: "center", alignItems: 'center' }}>
                <Select
                  value={eType}
                  onChange={(e) => {
                    setEType(e.target.value);
                  }}
                  style={{ fontSize: "15px"}}
                >
                  <MenuItem value="All">All Categories</MenuItem>
                  {employeeType &&
                    Object.entries(employeeType).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          }
          url={url}
          actions={actions}
          portalPermissions={{
            create: props.user.role.employees_create,
            edit: props.user.role.employees_modify,
            delete: props.user.role.employees_delete,
          }}
          unchangedData={unchangedData}
          objectForm={EmployeeForm}
          tableColumns={tableColumns}
          data={showData}
          activeIds={activeIds}
          fetchData={fetchData}
          orderBy={"employee_name"}
          fixedOrderBy={"category_order"}
          searchFields={[
            "employee_name",
            "email",
            "phone_number",
            "work_station",
            "role_name",
          ]}
          pageHeader={(props.company.company_name || "") + " | Employees"}
          loadData={loadData}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={
            <Grid container direction="row">
              <Grid
                item
                xs={12}
                style={{ textAlign: "center", marginBottom: 15 }}
                gutterbottom
              >
                Employees{" "}
              </Grid>
              <Grid container direction="row">
                <Grid item xs={4} style={{ fontSize: "12px" }}>
                  <Checkbox
                    className={classes.checkboxColor}
                    checked={showActive}
                    onClick={(e) => {
                      setShowActive(!showActive);
                    }}
                  />
                  Active
                </Grid>
                <Grid item xs={4} style={{ fontSize: "12px" }}>
                  <Checkbox
                    className={classes.checkboxColor}
                    checked={showDisabled}
                    onClick={(e) => {
                      setShowDisabled(!showDisabled);
                    }}
                  />
                  Inactive
                </Grid>
                <Grid item xs={4} style={{ fontSize: "12px" }}>
                  <Select
                    value={site}
                    onChange={(e) => {
                      setSite(e.target.value);
                    }}
                  >
                    <MenuItem value="All">All Sites</MenuItem>
                    {sites &&
                      Object.entries(sites).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
          }
          url={url}
          actions={actions}
          portalPermissions={{
            create: props.user.role.employees_create,
            edit: props.user.role.employees_modify,
            delete: props.user.role.employees_delete,
          }}
          unchangedData={unchangedData}
          objectForm={EmployeeForm}
          tableColumns={tableColumns}
          data={showData}
          activeIds={activeIds}
          fetchData={fetchData}
          orderBy={"employee_name"}
          fixedOrderBy={"category_order"}
          searchFields={[
            "employee_name",
            "email",
            "phone_number",
            "work_station",
            "role_name",
          ]}
          pageHeader={(props.company.company_name || "") + " | Employees"}
          loadData={loadData}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Employees);
